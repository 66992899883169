import { SUPERBLOCKS_AUTHORIZATION_HEADER } from "@superblocksteam/shared";
import { omit } from "lodash";
import TokenProvider from "auth/token";
import { ExtendedRequestInit } from "store/utils/client";
import { ComponentEditAction, SortedProperties } from "./types";

export type FeedbackAction = {
  action: "accept" | "discard" | "exit" | "redo" | "no_changes";
  type: string;
  modified: boolean;
  changeset?: Array<ComponentEditAction>;
  actionsRequestId: string;
  userFeedback?: string;
};

export type AiSmartTags = {
  mappings: Array<{
    match: string;
    replaces: string;
  }>;
};

export const sendAiWidgetEditActionsFeedback = async ({
  actionsRequestId,
  action,
  modified,
  type,
  changeset,
  aiServiceURL,
  userFeedback,
}: FeedbackAction & {
  aiServiceURL: string;
}): Promise<void> => {
  const authorizationJwt = TokenProvider.getToken();

  await fetch(`${aiServiceURL}/feedback`, {
    method: "POST",
    body: JSON.stringify({
      feedbacks: [
        {
          id: actionsRequestId,
          action,
          modified,
          component: type,
          changeset,
          userFeedback,
        },
      ],
    }),
    headers: {
      "Content-Type": "application/json",
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  });
};

export const getAiWidgetEditActionsStream = async ({
  existingWidget,
  prompt,
  context,
  signal,
  onMessage,
  onError,
  onResponse,
  aiServiceURL,
  entityNames,
  wasSuggestion,
}: {
  existingWidget: SortedProperties;
  prompt: string;
  context: Record<string, any>;
  signal?: AbortSignal;
  onMessage: (message: any) => void;
  onError: (error: string) => void;
  onResponse: (response: Response) => void;
  aiServiceURL: string;
  entityNames: Array<string>;
  wasSuggestion?: boolean;
}) => {
  const { streamServerSideEvents } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );

  await streamServerSideEvents({
    url: wasSuggestion
      ? `${aiServiceURL}/systems/comedt?suggestion=true`
      : `${aiServiceURL}/systems/comedt`,
    body: {
      context,
      component: omit(existingWidget, ["widgetId"]),
      type: existingWidget.staticProperties.widgetType,
      prompt,
      entities: entityNames.map((entity) => ({ name: entity })),
    },
    signal,
    onMessage,
    onResponse,
    onComplete: () => {},
    onError,
  });
};

export const getAiPromptTags = async ({
  prompt,
  entityNames,
  aiServiceURL,
}: {
  prompt: string;
  entityNames: string[];
  aiServiceURL: string;
}): Promise<AiSmartTags> => {
  const requestBody = {
    request: prompt,
    entities: entityNames.map((entity) => ({ name: entity })),
  };

  const authorizationJwt = TokenProvider.getToken();

  const response = await fetch(`${aiServiceURL}/context/extract`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    priority: "low",
    headers: {
      "Content-Type": "application/json",
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  } as ExtendedRequestInit);

  return response.json();
};
